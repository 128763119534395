
import { ElLoading } from 'element-plus'
import { reactive } from 'vue'

var loading = reactive({
    info: null
})

// Loading.openLoading()
// Loading.closeLoading()


export default {
    openLoading() {
        loading.info = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
    },
    closeLoading() {
        console.log();
        if(loading.info){
            loading.info.close()
        }
    },

}