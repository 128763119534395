/*
 * @Author: wangpeng 306466972@qq.com
 * @Date: 2024-04-23 14:09:05
 * @LastEditors: wangpeng 306466972@qq.com
 * @LastEditTime: 2024-05-13 17:57:34
 * @FilePath: \万博项目\git\新华社项目\新建文件夹\se-opinion-app\src\utils\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

const baseApi = "/api";


export default {

    getsummary: `${baseApi}/se/opinions/summary`, //获取舆情快报初始所有的数据

    getchart: `${baseApi}/se/opinions/summary/chart`, //获取舆情快报下面的图标

    // 获取【舆情看板 --> 预警列表】数据
    findSummaries: `${baseApi}/se/summaries`,

    // 获取实时舆情数据  /api/se/contents
    findContents: `${baseApi}/se/contents`,

    getMonitorCompany: `${baseApi}/se/monitor/company/`,  //获取通知消息列表

    getNRLY: `${baseApi}/se/summaries/nrlys`, //获取舆情快报初始所有的数据

    // 登录的接口
    login: `/auth/oauth2/token`,  //获取通知消息列表

    // 舆情监控的接口
    getMonitor: `/api/se/monitor/company/`,  //获取通知消息列表

    getversion: `/tianjian/api/xinhua/rep/listReportDate`,  //获取报告期version版本接口
    getriskData: `/tianjian/api/xinhua/rep/riskData`,  //财务风险统计和合理性分析接口
    getnoticeData: `/tianjian/api/xinhua/rep/noticeData`,  //财务预警占比分析接口

    getscoreData: `/tianjian/api/xinhua/rep/scoreData`,  //财务健康度趋势 和财务质量度趋势图接口

    getindustryRank: `/tianjian/api/xinhua/rep/industryRank`,  //财务同业比较接口
    getindexHistory: `/tianjian/api/xinhua/rep/indexHistory`,  //指标质量趋势接口
    getpdfDown: `/tianjian/api/xinhua/rep/pdfDown`,  //报告下载接口


    //   /api/se/monitor/company/{agdm}/tracer?days={days}


}
