/*
 * @Author: wangpeng 306466972@qq.com
 * @Date: 2024-04-22 15:21:39
 * @LastEditors: wangpeng 306466972@qq.com
 * @LastEditTime: 2024-05-21 10:19:29
 * @FilePath: \万博项目\git\新华社项目\新建文件夹\se-opinion-app\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: wangpeng 306466972@qq.com
 * @Date: 2024-04-22 15:21:39
 * @LastEditors: wangpeng 306466972@qq.com
 * @LastEditTime: 2024-05-06 09:20:24
 * @FilePath: \万博项目\git\新华社项目\新建文件夹\se-opinion-app\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: wangpeng 306466972@qq.com
 * @Date: 2024-04-17 11:14:04
 * @LastEditors: wangpeng 306466972@qq.com
 * @LastEditTime: 2024-04-23 10:15:48
 * @FilePath: \万博项目\git\新华社项目\demo\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home  
  // },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  },

  {
    path: '/financeHealth', //财务健康
    name: 'financeHealth',
    meta: {
      title: '财务健康',
    },
    component: () => import('@/views/financeHealth/index.vue'),
  },
  {
    path: '/login', //登录
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/',
    name: 'layout',
    component: () => import('@/layout/index.vue'),
    redirect: '/bulletin',
    children: [
      {
        path: '/home', //首页中心内容
        name: 'home',
        meta: {
          title: '首页',
        },
        component: () => import('@/views/home/index.vue'),
      },

      {
        path: '/bulletin', //舆情快报
        name: 'bulletin',
        meta: {
          title: '舆情快报',
        },
        component: () => import('@/views/publicBulletin/index.vue'),
      },
      {
        path: '/complianceTrain', //新的合规培训
        name: 'complianceTrain',
        meta: {
          title: '合规培训',
        },
        component: () => import('@/views/complianceTrain/index.vue'),
      },
      {
        path: '/training', //合规培训
        name: 'training',
        meta: {
          title: '合规培训',
        },
        component: () => import('@/views/training/index.vue'),
        redirect: '/training/strategic',
        children: [
          {
            path: '/training/strategic',
            name: 'strategic',
            meta: {
              title: '战略管理',
            },
            redirect: '/training/strategic/offline',
            component: () => import('@/views/training/strategic/index.vue'),
            children: [
              {
                path: '/training/strategic/offline',
                name: 'trainingStrategicOffline',
                component: () => import('@/views/training/strategic/offline.vue'),
              },
              {
                path: '/training/strategic/online',
                name: 'trainingStrategicOnline',
                component: () => import('@/views/training/strategic/online.vue'),
              },
              {
                path: '/training/strategic/detail/:id',
                name: 'trainingStrategicDetail',
                component: () => import('@/views/training/strategic/detail.vue'),
              }
            ]
          }
        ]
      },

      {
        path: '/warningAnalyse', //预警分析
        name: 'warningAnalyse',
        meta: {
          title: '预警分析',
        },
        component: () => import('@/views/warningAnalyse/index.vue'),
      },
      {
        path: '/feelReport', //预警分析
        name: 'feelReport',
        meta: {
          title: '舆情报告',
        },
        component: () => import('@/views/feelReport/index.vue'),
      },
      {
        path: '/feelBoard', //舆情看板
        name: 'feelBoard',
        meta: {
          title: '舆情看板',
        },
        component: () => import('@/views/feelBoard/index.vue'),
      },
      {
        path: '/feelTrace', //舆情追踪
        name: 'feelTrace',
        meta: {
          title: '舆情追踪',
        },
        component: () => import('@/views/feelTrace/index.vue'),
      },
      {
        path: '/feelMonitor', //舆情监控
        name: 'feelMonitor',
        meta: {
          title: '舆情监控',
        },
        component: () => import('@/views/feelMonitor/index.vue'),
      },
      {
        path: '/mediaManage', //媒体管理
        name: 'mediaManage',
        meta: {
          title: '媒体管理',
        },
        component: () => import('@/views/mediaManage/index.vue'),
      },
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  var arr = ['/login']
  let token = JSON.parse(<string>localStorage.getItem('access_token'))

  if (arr.includes(to.path)) {
    localStorage.clear();
    return next();
  }

  if (token) {
    return next();
  } else {
    return next('/login');
  }
})













export default router
