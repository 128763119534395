import axios from 'axios'
import qs from 'qs'
import { ElLoading } from 'element-plus'
import { ElMessage } from 'element-plus'

import Loading from '@/components/loading/index'

var BASE_API_URL = "production" === process.env.NODE_ENV ? process.env.VUE_APP_API : ""

axios.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
})

axios.interceptors.response.use(response => {
    return response
}, error => {
    return Promise.resolve(error.response)
})

var isNext = true


function checkStatus(response) {
    // 如果后台没响应则提示服务器错误
    if (!response || response.status == 500 && isNext) {
        Loading.closeLoading()

        ElMessage.error("服务器错误，请稍后重试")
        return response

    } else if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
        Loading.closeLoading()
        // 如果http状态码正常，则直接返回数据
        return response
    } else {
        Loading.closeLoading()
        return {
            status: -404,
            msg: '网络异常'
        }
    }

}

function checkCode(res) {


    if (res.data.code == 500) {


        Loading.closeLoading()
        ElMessage({
            message: res.data.message||'服务器错误，请稍后重试',
            type: 'error',
            offset: 60
        })
        // ElMessage.error("服务器错误，请稍后重试")
        return res

    }





    return res
}

export default {
    post(url, data, contype, isdesktop, superDogKey) {
        return axios({
            method: 'post',
            url: BASE_API_URL + url,
            data: contype ? data : qs.stringify(data),
            timeout: 120000,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': contype ? contype : 'application/x-www-form-urlencoded; charset=UTF-8',
                // 'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8',

                // 'Content-Type': 'application/json; charset=UTF-8'
                'superDogKey': superDogKey,
                'isdesktop': isdesktop,
                'hartRoute': window.location.href,

                'Authorization': JSON.parse(localStorage.getItem('token')),
                'DATA-OS-TOKEN': JSON.parse(localStorage.getItem('access_token')),
	//	'token': 'eyJhbGciOiJIUzI1NiJ9.eyJpZCI6ImY1OGVjNjE5MmIxYTQ3Mjg5MTFlYzI0NTBmZDU3ZDM3IiwiaWF0IjoxNzE0NDYzODIxLCJqdGkiOiJhZjgzNjNiOWQ4NjM0NGI3YjVlNjVjOTk2NWQ0NzYwNSJ9.LVZ7oqRYd9BaZeV06luUmK8gk_btYX7TzgVrv344ABo',


            }
        }).then((response) => {
            return checkStatus(response)
        }).then((res) => {
            return checkCode(res)
        })
    },

    postJson(url, data, contype, isdesktop, superDogKey) {


        console.log(data);

        return axios({
            method: 'post',
            url: BASE_API_URL + url,
            data: data,
            timeout: 120000,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                // 'Content-Type': contype ? contype : 'application/x-www-form-urlencoded; charset=UTF-8',
                'Content-Type': 'application/json;charset=UTF-8',
                'superDogKey': superDogKey,
                'isdesktop': isdesktop,
                'hartRoute': window.location.href,
                'Authorization': JSON.parse(localStorage.getItem('token')),
                'DATA-OS-TOKEN': JSON.parse(localStorage.getItem('access_token')),


            }
        }).then((response) => {
            return checkStatus(response)
        }).then((res) => {
            return checkCode(res)
        })
    },


    //后端导出
    postExcel(url, data, contype, isdesktop, superDogKey) {

        console.log(url);

        return axios({
            method: 'post',
            url: BASE_API_URL + url,
            data: contype ? data : qs.stringify(data),
            timeout: 120000,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': contype ? contype : 'application/x-www-form-urlencoded; charset=UTF-8',
                // 'Content-Type': 'application/json; charset=UTF-8'
                'superDogKey': superDogKey,
                'isdesktop': isdesktop,
                'hartRoute': window.location.href,
                'Authorization': JSON.parse(localStorage.getItem('token')),
                'DATA-OS-TOKEN': JSON.parse(localStorage.getItem('access_token')),
          //      'Token': 'eyJhbGciOiJIUzI1NiJ9.eyJpZCI6ImY1OGVjNjE5MmIxYTQ3Mjg5MTFlYzI0NTBmZDU3ZDM3IiwiaWF0IjoxNzE0NDYzODIxLCJqdGkiOiJhZjgzNjNiOWQ4NjM0NGI3YjVlNjVjOTk2NWQ0NzYwNSJ9.LVZ7oqRYd9BaZeV06luUmK8gk_btYX7TzgVrv344ABo',


            },
            responseType: 'blob'
        }).then((response) => {
            return checkStatus(response)
        }).then((res) => {
            return checkCode(res)
        })
    },

    get(url, params) {
        return axios({
            method: 'get',
            url: BASE_API_URL + url,
            params,
            // get 请求时带的参数
            timeout: 120000,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'hartRoute': window.location.href,
                'Authorization': JSON.parse(localStorage.getItem('token')),
                'DATA-OS-TOKEN': JSON.parse(localStorage.getItem('access_token')),

            }
        }).then((response) => {

            return checkStatus(response)
        }).then((res) => {
            return checkCode(res)
        })
    }
}
