/*
 * @Author: wangpeng 306466972@qq.com
 * @Date: 2024-04-22 15:21:39
 * @LastEditors: wangpeng 306466972@qq.com
 * @LastEditTime: 2024-05-21 10:06:32
 * @FilePath: \万博项目\git\新华社项目\新建文件夹\se-opinion-app\src\store\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// store.js
import { defineStore } from 'pinia'

export const useWarningStore = defineStore('warning', {
  state: () => {
    return {
      info: {},
      activeNameTop: '',
      activeNameLeft: '',
      isStartWarningTimer:true,//开启

    }
  },
  actions: {
    updateInfo(obj) {
      this.info = obj
    },
    updateActiveNameTop(val) {
      this.activeNameTop = val
    },
    updateActiveNameLeft(val) {
      this.activeNameLeft = val

    },
    updateIsStartWarningTimer(val) {
      this.isStartWarningTimer = val

    }
  }
})