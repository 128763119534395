/*
 * @Author: wangpeng 306466972@qq.com
 * @Date: 2024-04-17 11:14:04
 * @LastEditors: wangpeng 306466972@qq.com
 * @LastEditTime: 2024-05-21 10:18:52
 * @FilePath: \万博项目\git\新华社项目\demo\src\main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
const app = createApp(App)


import { createPinia } from 'pinia'
const pinia = createPinia()
app.use(pinia)

app.use(router)


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPlus)



// 将自动注册所有组件为全局组件

import '@/assets/iconfont/iconfont.css'





import vue3TreeOrg from 'vue3-tree-org'
import 'vue3-tree-org/lib/vue3-tree-org.css'
app.use(vue3TreeOrg)








app.mount('#app')

